<template>
  <footer>
    <div class="text-center  w-full py-5">
        <span>
            <fa-icon icon="fa-regular fa-copyright" />
            {{ new Date().getFullYear() }} Whilmer Realigue. All Rights Reserved.
        </span>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>