<template>
  <section class="flex flex-col justify-center items-center text-center mb-16" id="contact"> 
    <h1 class="uppercase text-2xl font-bold mb-6 md:mt-9">Contact</h1>
    <p class="w-full lg:max-w-[500px] mb-5">
      Currently, I am looking for opportunities and accepting freelance
      projects. If you think I am a good asset, kindly send me a message.
    </p>
    <span class="py-2 px-10 border-[1px] border-solid border-black">
        whilreal@gmail.com
    </span>
  </section>
</template>

<script>
export default {
   data:()=>({

   }),
  
};
</script>

<style>
</style>