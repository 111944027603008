<template>
  <section class=" text-center py-7" id="skills">
    <h1 class="text-[32px] font-bold">TECHNOLOGIES</h1>
    <p class="py-5">
      These are the technology stacks i am currently know, and learning more.
    </p>
    <div class="lg:w-full lg:flex lg:flex-row lg:justify-center">
      <ul class="grid grid-cols-2 mx-10 gap-2 font-medium  lg:min-w-[500px] justify-center items-center" >
        <li
          class="
            shadow-custom-sm
            border-[1px] border-solid border-black
            py-2
            px-5
            
          "
        >
          HTML5
        </li>
        <li
          class="
            shadow-custom-sm
            border-[1px] border-solid border-black
            py-2
            
          "
        >
          CSS
        </li>

        <li
          class="
            shadow-custom-sm
            border-[1px] border-solid border-black
            col-span-2
            py-2
            
            mx-14
          "
        >
          TailwindCSS
        </li>

        <li
          class="
            shadow-custom-sm
            border-[1px] border-solid border-black
            py-2
            px-5
          "
        >
          Vuetify
        </li>
        <li
          class="
            shadow-custom-sm
            border-[1px] border-solid border-black
            py-2
            px-5
          "
        >
          GIT
        </li>

        <li
          class="
            shadow-custom-sm
            border-[1px] border-solid border-black
            col-span-2
            py-2
            
            mx-14
          "
        >
          javascript es6
        </li>
        <li
          class="
            shadow-custom-sm
            border-[1px] border-solid border-black
            py-2
           
          "
        >
          Bootstrap
        </li>
        <li
          class="
            shadow-custom-sm
            border-[1px] border-solid border-black
            py-2
            
          "
        >
          Wordpress
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
.shadow-custom-sm {
  box-shadow: rgb(0 0 0 / 15%) 0px 3px 3px 0px;
}
</style>